var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-0", attrs: { flat: "" } },
    [
      _vm.fieldsLoading || _vm.hoursList.length <= 0
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(9, function (n) {
              return _c(
                "v-col",
                { key: n, attrs: { cols: "12", sm: "6", lg: "3" } },
                [
                  _c(
                    "v-sheet",
                    { staticClass: "mt-2" },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "list-item" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c(
            "v-card",
            { staticClass: "timer--wrapper", attrs: { flat: "" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            wrap: "",
                            "align-center": "",
                            "justify-left": "",
                            "fill-height": "",
                          },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "" } },
                            [
                              _c(
                                "v-form",
                                { ref: "weekNavigation" },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { "pa-0": "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "align-center": "",
                                            "justify-left": "",
                                            row: "",
                                            "fill-height": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              class: _vm.$vuetify.breakpoint
                                                .smAndUp
                                                ? "text-xs-right"
                                                : "pl-8",
                                              attrs: {
                                                xs3: "",
                                                sm3: "",
                                                md2: "",
                                                lg1: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    fab: "",
                                                    dark: "",
                                                    small: "",
                                                    color: "secondary",
                                                  },
                                                  on: { click: _vm.prevWeek },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { dark: "" } },
                                                    [_vm._v("mdi-chevron-left")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "px-1",
                                              attrs: {
                                                xs2: "",
                                                sm3: "",
                                                md3: "",
                                                lg3: "",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    _vm.$t(
                                                      "message.timer.week"
                                                    ),
                                                  rules: _vm.weekValidation,
                                                },
                                                on: {
                                                  keypress: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.goToCustomWeek(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.customWeek,
                                                  callback: function ($$v) {
                                                    _vm.customWeek = $$v
                                                  },
                                                  expression: "customWeek",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "px-1",
                                              attrs: {
                                                xs3: "",
                                                sm3: "",
                                                md3: "",
                                                lg3: "",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    _vm.$t(
                                                      "message.timer.year"
                                                    ),
                                                  rules: _vm.yearValidation,
                                                },
                                                on: {
                                                  keypress: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.goToCustomWeek(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.customYear,
                                                  callback: function ($$v) {
                                                    _vm.customYear = $$v
                                                  },
                                                  expression: "customYear",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              class: _vm.$vuetify.breakpoint
                                                .smAndUp
                                                ? "px-6"
                                                : "px-2",
                                              attrs: {
                                                xs2: "",
                                                sm2: "",
                                                md2: "",
                                                lg2: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    fab: "",
                                                    small: "",
                                                    dark: "",
                                                    color: "secondary",
                                                  },
                                                  on: { click: _vm.nextWeek },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { dark: "" } },
                                                    [
                                                      _vm._v(
                                                        "mdi-chevron-right"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs2: "",
                                                sm1: "",
                                                md2: "",
                                                lg2: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    fab: "",
                                                    small: "",
                                                    dark: "",
                                                    color: "secondary",
                                                  },
                                                  on: { click: _vm.goToToday },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { dark: "" } },
                                                    [_vm._v("mdi-calendar")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { "justify-center": "" } },
                                [
                                  _c(
                                    "h2",
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("message.common.total")) +
                                          " : " +
                                          _vm._s(
                                            _vm.$formatter.replaceDotWithComma(
                                              this.totalHoursInWeek
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-2",
                                          attrs: { color: "primary" },
                                          on: {
                                            click: _vm.deliverHoursHandler,
                                          },
                                        },
                                        [_vm._v("mdi-check-circle-outline")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.loading & !_vm.fieldsLoading
                ? _c(
                    "v-overlay",
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          indeterminate: "",
                          size: "64",
                          color: "#f6b21b",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card-text",
                { staticClass: "pa-0", staticStyle: { "font-weight": "500" } },
                [
                  _c(
                    "div",
                    { staticClass: "timer_module" },
                    _vm._l(_vm.hoursList, function (item, index) {
                      return _c(
                        "table",
                        {
                          key: index,
                          class: item.is_week_end ? "week_end_style" : "",
                        },
                        [
                          _c(
                            "tr",
                            {
                              class: "" + (item.is_lock ? "day-locked" : ""),
                              on: {
                                click: function ($event) {
                                  item.is_show = !item.is_show
                                },
                              },
                            },
                            [
                              _c("td", { attrs: { width: "2%" } }),
                              _c("td", { attrs: { width: "15%" } }, [
                                _vm._v(_vm._s(item.short_day)),
                              ]),
                              _c("td", { attrs: { width: "23%" } }, [
                                _vm._v(
                                  _vm._s(_vm.$formatter.filterDate(item.date))
                                ),
                              ]),
                              _c("td", { attrs: { width: "1%" } }),
                              _c("td", { attrs: { width: "34%" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$formatter.replaceDotWithComma(
                                      item.totalInWork
                                    )
                                  ) +
                                    " (" +
                                    _vm._s(
                                      _vm.$formatter.replaceDotWithComma(
                                        item.totalInAbsense
                                      )
                                    ) +
                                    ")t"
                                ),
                              ]),
                              _c(
                                "td",
                                { attrs: { width: "10%" } },
                                [
                                  item.statusName === "NOT_DELIVERED"
                                    ? _c("v-icon", [_vm._v("mdi-send")])
                                    : _vm._e(),
                                  item.statusName === "DELIVERED"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "#e0b517" } },
                                        [_vm._v("mdi-timer-sand-empty")]
                                      )
                                    : _vm._e(),
                                  item.statusName === "NOT_APPROVED"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "#ea1616" } },
                                        [_vm._v("mdi-information-outline")]
                                      )
                                    : _vm._e(),
                                  item.statusName === "APPROVED"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "#41b883" } },
                                        [
                                          _vm._v(
                                            "mdi-checkbox-marked-circle-outline"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "td",
                                { attrs: { width: "15%" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      class: item.is_week_end
                                        ? "theme--dark"
                                        : "",
                                      attrs: { icon: "" },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            item.is_show
                                              ? "mdi-chevron-up"
                                              : "mdi-chevron-down"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          item.is_show
                            ? _c(
                                "tr",
                                {
                                  class: item.is_week_end
                                    ? "week_end_style_inverse"
                                    : "",
                                },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "hourlist_custom_padding",
                                      attrs: { colspan: "7" },
                                    },
                                    [
                                      item.hours && item.hours.length > 0
                                        ? _vm._l(
                                            item.hours,
                                            function (hour, i) {
                                              return _c(
                                                "v-card",
                                                {
                                                  key: i,
                                                  staticClass: "mb-2",
                                                  attrs: { outlined: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.isClickable
                                                        ? _vm.editSingleRecord(
                                                            hour.id
                                                          )
                                                        : null
                                                    },
                                                  },
                                                },
                                                [
                                                  hour.type ===
                                                  _vm.constants.WORKING
                                                    ? _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "mx-0 custom_row_color",
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "px-1 py-1",
                                                              attrs: {
                                                                cols: "9",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-toolbar-title",
                                                                {
                                                                  staticClass:
                                                                    "subtitle-2 pl-1 normal_white_space",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      hour.project_name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c("v-spacer"),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "px-1 py-1",
                                                              attrs: {
                                                                cols: "3",
                                                              },
                                                            },
                                                            [
                                                              _vm.isSameDays(
                                                                hour
                                                              )
                                                                ? _c(
                                                                    "v-toolbar-title",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2 px-0 font-weight-regular normal_white_space",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          "(" +
                                                                            _vm.setFromTillHours(
                                                                              hour
                                                                            ) +
                                                                            ")"
                                                                        )
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "pa-1 pl-2",
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        { staticClass: "mx-0" },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0",
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "mb-1 body-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      hour.activity_name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle",
                                                                {
                                                                  staticClass:
                                                                    "body-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      hour.worktype_name ||
                                                                        ""
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              hour.type ===
                                                                _vm.constants
                                                                  .NON_WORKING &&
                                                              _vm.isSameDays(
                                                                hour
                                                              ) &&
                                                              _vm.hasHourValues(
                                                                hour
                                                              )
                                                                ? _c(
                                                                    "v-list-item-subtitle",
                                                                    {
                                                                      staticClass:
                                                                        "body-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          "(" +
                                                                            _vm.setFromTillHours(
                                                                              hour
                                                                            ) +
                                                                            ")"
                                                                        )
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0 text-right",
                                                              class:
                                                                hour.type ===
                                                                _vm.constants
                                                                  .NON_WORKING
                                                                  ? "pt-1"
                                                                  : "",
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "pa-0 pb-1 ma-0 font-weight-medium",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getStatusText(
                                                                        hour.state
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-chip",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      _vm.getStatusColor(
                                                                        hour.state
                                                                      ),
                                                                    "text-color":
                                                                      _vm.getStatusForeColor(
                                                                        hour.state
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      hour.amount
                                                                        ? _vm.getBreakTimeReducedHour(
                                                                            hour
                                                                          )
                                                                        : "0,0"
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : [
                                            _c(
                                              "div",
                                              { staticClass: "list--items" },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "pt-1 pb-1 pr-2 pl-2",
                                                    attrs: { sm12: "" },
                                                  },
                                                  [
                                                    _c(
                                                      "h3",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "message.common.noResults"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
      false
        ? _c(
            "v-row",
            { staticClass: "mt-5 float-right" },
            [
              _c(
                "v-speed-dial",
                {
                  staticClass: "timer_speed_dial_position",
                  attrs: {
                    right: "",
                    direction: "left",
                    transition: "slide-y-transition",
                    bottom: "bottom",
                    fixed: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function () {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  small: "",
                                  color: "secondary",
                                  dark: "",
                                  fab: "",
                                },
                                model: {
                                  value: _vm.fab,
                                  callback: function ($$v) {
                                    _vm.fab = $$v
                                  },
                                  expression: "fab",
                                },
                              },
                              [
                                !_vm.fab
                                  ? _c("v-icon", [_vm._v("mdi-alarm")])
                                  : _c("v-icon", [_vm._v("mdi-close")]),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    4102552778
                  ),
                  model: {
                    value: _vm.fab,
                    callback: function ($$v) {
                      _vm.fab = $$v
                    },
                    expression: "fab",
                  },
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        color: "accent",
                                        fab: "",
                                        small: "",
                                      },
                                      on: { click: _vm.deliverHoursHandler },
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-check-circle-outline"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        198380810
                      ),
                    },
                    [_c("span", [_vm._v("Deliver")])]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        dark: "",
                        small: "",
                        color: "secondary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openFormHandler()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-plus-circle-outline")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }